import React from 'react';
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';

import { colors } from 'styles';

type Props = { children: React.ReactNode };

let theme = createTheme({
  palette: { primary: { main: colors.PRIMARY } },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            backgroundColor: colors.DARK_BACKGROUND,
            borderRadius: 0,
          },
          fieldset: { borderColor: colors.PRIMARY },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: colors.PRIMARY,
          borderRadius: 0,
        },
      },
    },
  },
  typography: {
    fontFamily: 'Cera Pro',
    h1: { fontFamily: 'Baskerville Old Face' },
    h2: { fontFamily: 'Baskerville Old Face' },
    h3: { fontFamily: 'Baskerville Old Face', lineHeight: 1.1 },
    h4: { fontFamily: 'Baskerville Old Face', lineHeight: 1.2 },
    button: { textTransform: 'none', fontWeight: 'bold' },
  },
});

theme = responsiveFontSizes(theme);

export const MUIProvider: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { color: colors.PRIMARY, backgroundColor: colors.BACKGROUND },
        }}
      />
      {children}
    </ThemeProvider>
  );
};
