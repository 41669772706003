/* eslint-disable react/jsx-filename-extension */
import 'firebase/analytics';
import 'firebase/functions';
import { MUIProvider } from './src/components';

require('./src/styles/typography.css');

export const wrapRootElement = ({ element }) => {
  return <MUIProvider>{element}</MUIProvider>;
};

export const wrapPageElement = ({ element }) => {
  return element;
};
